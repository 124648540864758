import { useSelector } from "react-redux";
import { FaRegFilePdf } from "react-icons/fa6";
import { Card, Flex, Text } from "@chakra-ui/react";

function DownloadTab(){
    const user = useSelector((state) => state.userReducer);

    const handleDownloadClick = () => {
        window.open("https://krystel.s3.eu-west-2.amazonaws.com/BeABusinessman.pdf", "_blank");
    };
    return (
        <>
            {
                user.download === true ?
                <>
                    <Card p={5}>
                        <Flex gap={3} cursor={'pointer'} onClick={handleDownloadClick}>
                            <Text my={'auto'}><FaRegFilePdf/></Text>
                            <Text my={'auto'} fontSize={'sm'}>Be a Businessman</Text>
                        </Flex>
                    </Card>
                </>
                :
                <> 
                    <Flex p={5} textAlign={'center'}>
                        <Text fontSize={'xs'} color={"gray"}>You do not have any available resource to download at the moment</Text>
                    </Flex>
                </>
            }
        </>
    )
}

export default DownloadTab