import { Card, Flex, Stat, StatHelpText, StatLabel, StatNumber, Tag, Text, useToast } from "@chakra-ui/react"
import { primaryColour } from "../../../lib/settings"
import { useSelector } from "react-redux";
import { MdContentCopy } from "react-icons/md";
import { copyToClipboard, krystelValuer } from "../../../lib/support";
import { BsBoxes } from "react-icons/bs";

function IPCETab() {

    const user = useSelector((state) => state.userReducer);
    const toast = useToast()
    return (
        <>
            <Card p={5}>
                <Flex>
                    <Flex width={"85%"} direction={'column'}>
                        <Stat>
                            <StatLabel>Your available <b>Krystel Credits</b></StatLabel>
                            <StatNumber color={primaryColour}>{krystelValuer(user.ipceWalletBalance)}</StatNumber>
                            <StatHelpText color={'gray.600'}>~ $ {(user.ipceWalletBalance/10000).toFixed(2)}</StatHelpText>
                        </Stat>
                        {
                            user.ipceWalletHash === '' ?
                            <Flex mt={1}>
                                <Tag fontSize={'xs'} color={'gray'}>Address not allocated</Tag> 
                            </Flex>
                            :
                            <Flex gap={1} mt={1} cursor={'pointer'} onClick={() => copyToClipboard(user.ipceWalletHash, toast, "Address")}>
                                <Text fontSize={'xs'} color={'gray.500'}>{user.ipceWalletHash}</Text>
                                <Text fontSize={'sm'} my={'auto'}><MdContentCopy/></Text>
                            </Flex> 
                        }
                    </Flex>
                    <Flex width={"15%"}>
                        <Text color={"gray.600"} fontSize={40} my={'auto'}><BsBoxes /></Text>
                    </Flex>
                </Flex>
            </Card>
        </>
    )
}

export default IPCETab