import "../../../HUD.css"
function HUDLoader() {
    return (
        <>
            <div className="svg-frame">
                <svg style={{ '--i': 0 }}  id="svg">
                    <g id="big-outter">
                        <g id="squares">
                            <path id="Ellipse 25" d="M383.931 285.267C374.922 309.242 360.656 330.88 342.188 348.583C323.72 366.285 301.522 379.599 277.234 387.541L277 386.821C301.181 378.915 323.28 365.659 341.667 348.035C360.053 330.411 374.255 308.869 383.225 285L383.931 285.267Z" stroke="#ffffff" strokeWidth="6" strokeLinecap="square" strokeDasharray="1 15" />
                        </g>
                        <g id="bottom-dots">
                            <path id="Ellipse 22" d="M368.63 350.215C350.6 370.742 328.367 387.15 303.437 398.328C278.508 409.505 251.466 415.189 224.146 414.995C196.827 414.801 169.868 408.733 145.1 397.202C120.332 385.672 98.3341 368.949 80.5984 348.168" stroke="#ffffff" strokeWidth="2" strokeLinecap="square" strokeDasharray="1 15" />
                            <path id="Ellipse 21" d="M345.779 148.085C360.806 171.738 368.526 199.298 367.972 227.315C367.419 255.333 358.616 282.566 342.666 305.607L342.147 305.248C358.027 282.309 366.79 255.197 367.341 227.303C367.893 199.409 360.207 171.972 345.246 148.423L345.779 148.085Z" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.5 15" />
                        </g>
                        <g id="top-dots">
                            <path id="Ellipse 20" d="M92.4362 117.897C75.3686 139.201 63.6658 164.291 58.3117 191.059C52.9576 217.826 54.1089 245.488 61.6686 271.718L62.394 271.509C54.8677 245.395 53.7216 217.856 59.0519 191.207C64.3823 164.558 76.0333 139.578 93.0253 118.369L92.4362 117.897Z" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.5 15" />
                            <path id="Ellipse 23" d="M251.126 44.8182C226.015 41.2368 200.432 42.9587 176.027 49.8728" stroke="#ffffff" strokeWidth="2" strokeLinecap="square" strokeDasharray="0.5 15" />
                            <path id="Ellipse 24" d="M300.89 118.301C274.736 99.5021 242.549 91.1476 210.597 94.8638" stroke="#ffffff" strokeWidth="4" strokeLinecap="square" strokeDasharray="0.5 15" />
                        </g>
                        <g id="Ellipse 12">
                            <mask id="path-20-inside-6_115_2" fill="white">
                                <path d="M225.5 22C240.73 22 255.911 23.7181 270.755 27.1217L269.171 34.0299C254.846 30.7455 240.197 29.0875 225.5 29.0875L225.5 22Z" />
                            </mask>
                            <path d="M225.5 22C240.73 22 255.911 23.7181 270.755 27.1217L269.171 34.0299C254.846 30.7455 240.197 29.0875 225.5 29.0875L225.5 22Z" stroke="#ffffff" strokeWidth="8" mask="url(#path-20-inside-6_115_2)" />
                        </g>
                        <g id="Ellipse 11">
                            <mask id="path-21-inside-7_115_2" fill="white">
                                <path d="M76.2195 83.2711C89.0537 69.7053 103.677 57.9521 119.686 48.3363L125.449 57.9312C110.312 67.0233 96.4854 78.1363 84.3502 90.9633L76.2195 83.2711Z" />
                            </mask>
                            <path d="M76.2195 83.2711C89.0537 69.7053 103.677 57.9521 119.686 48.3363L125.449 57.9312C110.312 67.0233 96.4854 78.1363 84.3502 90.9633L76.2195 83.2711Z" fill="#ffffff" stroke="#ffffff" strokeWidth="8" mask="url(#path-21-inside-7_115_2)" />
                        </g>
                    </g>
                    <circle id="base" cx="228.5" cy="224.5" r="202" stroke="#ffffff" />
                    <mask id="path-2-inside-1_115_2" fill="white">
                        <path d="M165.046 416.801C208.726 431.214 256.011 430.328 299.119 414.287C342.228 398.247 378.591 368.008 402.224 328.548C425.858 289.088 435.354 242.758 429.149 197.183C422.944 151.607 401.408 109.502 368.086 77.7961L363.102 83.0341C395.235 113.608 416.001 154.21 421.985 198.158C427.968 242.106 418.811 286.782 396.022 324.833C373.232 362.884 338.167 392.043 296.598 407.511C255.028 422.979 209.432 423.834 167.312 409.935L165.046 416.801Z" />
                    </mask>
                    <path d="M165.046 416.801C208.726 431.214 256.011 430.328 299.119 414.287C342.228 398.247 378.591 368.008 402.224 328.548C425.858 289.088 435.354 242.758 429.149 197.183C422.944 151.607 401.408 109.502 368.086 77.7961L363.102 83.0341C395.235 113.608 416.001 154.21 421.985 198.158C427.968 242.106 418.811 286.782 396.022 324.833C373.232 362.884 338.167 392.043 296.598 407.511C255.028 422.979 209.432 423.834 167.312 409.935L165.046 416.801Z" stroke="#ffffff" strokeWidth="8" mask="url(#path-2-inside-1_115_2)" />
                </svg>

                <svg style={{ '--i': 1 }}  id="svg">
                    <g id="big-centro">
                        <circle id="Ellipse 19" cx="227.5" cy="224.5" r="158" stroke="#ffffff" />
                        <g id="Ellipse 29">
                            <mask id="path-23-inside-8_115_2" fill="white">
                                <path d="M70.3243 192.208C68.873 199.183 67.8935 206.247 67.3925 213.352L70.5546 213.575C71.0456 206.612 72.0055 199.689 73.4278 192.854L70.3243 192.208Z" />
                            </mask>
                            <path d="M70.3243 192.208C68.873 199.183 67.8935 206.247 67.3925 213.352L70.5546 213.575C71.0456 206.612 72.0055 199.689 73.4278 192.854L70.3243 192.208Z" stroke="#ffffff" strokeWidth="4" mask="url(#path-23-inside-8_115_2)" />
                        </g>
                        <g id="Ellipse 48">
                            <mask id="path-24-inside-9_115_2" fill="white">
                                <path d="M109.166 116.849C104.328 122.078 99.8478 127.626 95.756 133.457L98.3509 135.278C102.361 129.564 106.751 124.126 111.493 119.002L109.166 116.849Z" />
                            </mask>
                            <path d="M109.166 116.849C104.328 122.078 99.8478 127.626 95.756 133.457L98.3509 135.278C102.361 129.564 106.751 124.126 111.493 119.002L109.166 116.849Z" stroke="#ffffff" strokeWidth="4" mask="url(#path-24-inside-9_115_2)" />
                        </g>
                        <g id="Ellipse 49">
                            <mask id="path-25-inside-10_115_2" fill="white">
                                <path d="M156.914 81.6077C150.492 84.6902 144.285 88.2021 138.335 92.1196L140.079 94.7672C145.909 90.9281 151.992 87.4864 158.286 84.4655L156.914 81.6077Z" />
                            </mask>
                            <path d="M156.914 81.6077C150.492 84.6902 144.285 88.2021 138.335 92.1196L140.079 94.7672C145.909 90.9281 151.992 87.4864 158.286 84.4655L156.914 81.6077Z" stroke="#ffffff" strokeWidth="4" mask="url(#path-25-inside-10_115_2)" />
                        </g>
                        <g id="Ellipse 30">
                            <mask id="path-26-inside-11_115_2" fill="white">
                                <path d="M80.0639 287.513C88.7706 307.608 101.547 325.681 117.588 340.592L119.747 338.27C104.026 323.658 91.5052 305.946 82.9726 286.252L80.0639 287.513Z" />
                            </mask>
                            <path d="M80.0639 287.513C88.7706 307.608 101.547 325.681 117.588 340.592L119.747 338.27C104.026 323.658 91.5052 305.946 82.9726 286.252L80.0639 287.513Z" stroke="#ffffff" strokeWidth="4" mask="url(#path-26-inside-11_115_2)" />
                        </g>
                        <g id="Ellipse 38">
                            <mask id="path-27-inside-12_115_2" fill="white">
                                <path d="M284.312 76.5318C260.377 67.3417 234.569 64.0954 209.103 67.0713L210.125 75.8204C234.176 73.0099 258.55 76.0758 281.155 84.7552L284.312 76.5318Z" />
                            </mask>
                            <path d="M284.312 76.5318C260.377 67.3417 234.569 64.0954 209.103 67.0713L210.125 75.8204C234.176 73.0099 258.55 76.0758 281.155 84.7552L284.312 76.5318Z" fill="#ffffff" stroke="#ffffff" strokeWidth="4" mask="url(#path-27-inside-12_115_2)" />
                        </g>
                        <g id="Ellipse 46">
                            <mask id="path-28-inside-13_115_2" fill="white">
                                <path d="M376.684 278.04C385.344 253.908 388.021 228.035 384.485 202.64L375.761 203.855C379.1 227.838 376.572 252.273 368.393 275.064L376.684 278.04Z" />
                            </mask>
                            <path d="M376.684 278.04C385.344 253.908 388.021 228.035 384.485 202.64L375.761 203.855C379.1 227.838 376.572 252.273 368.393 275.064L376.684 278.04Z" fill="#ffffff" stroke="#ffffff" strokeWidth="4" mask="url(#path-28-inside-13_115_2)" />
                        </g>
                        <g id="Ellipse 47">
                            <mask id="path-29-inside-14_115_2" fill="white">
                                <path d="M90.923 321.469C106.609 343.562 127.42 361.52 151.572 373.802L159.476 358.26C137.838 347.256 119.194 331.168 105.141 311.375L90.923 321.469Z" />
                            </mask>
                            <path d="M90.923 321.469C106.609 343.562 127.42 361.52 151.572 373.802L159.476 358.26C137.838 347.256 119.194 331.168 105.141 311.375L90.923 321.469Z" fill="#ffffff" stroke="#ffffff" strokeWidth="4" mask="url(#path-29-inside-14_115_2)" />
                        </g>
                        <circle id="Ellipse 44" cx="227.5" cy="224.5" r="150" stroke="#ffffff" />
                    </g>
                </svg>

                <svg style={{ '--i': 2 }}  id="svg">
                    <g id="solo-lines">
                        <g id="left-line">
                            <mask id="path-18-inside-4_115_2" fill="white">
                                <path d="M109.714 84.729C87.8988 102.801 70.6164 125.728 59.2501 151.677C47.8838 177.625 42.7493 205.874 44.2574 234.162C45.7655 262.451 53.8744 289.994 67.9352 314.586C81.9959 339.179 101.618 360.139 125.231 375.789L126.101 374.477C102.693 358.963 83.2407 338.185 69.3019 313.805C55.3631 289.426 47.3245 262.122 45.8294 234.079C44.3344 206.036 49.4244 178.032 60.6921 152.308C71.9598 126.585 89.0923 103.856 110.719 85.9413L109.714 84.729Z" />
                            </mask>
                            <path d="M109.714 84.729C87.8988 102.801 70.6164 125.728 59.2501 151.677C47.8838 177.625 42.7493 205.874 44.2574 234.162C45.7655 262.451 53.8744 289.994 67.9352 314.586C81.9959 339.179 101.618 360.139 125.231 375.789L126.101 374.477C102.693 358.963 83.2407 338.185 69.3019 313.805C55.3631 289.426 47.3245 262.122 45.8294 234.079C44.3344 206.036 49.4244 178.032 60.6921 152.308C71.9598 126.585 89.0923 103.856 110.719 85.9413L109.714 84.729Z" stroke="#ffffff" strokeWidth="2" mask="url(#path-18-inside-4_115_2)" />
                        </g>
                        <g id="right-line">
                            <mask id="path-19-inside-5_115_2" fill="white">
                                <path d="M293.127 56.0696C319.474 66.6482 342.971 83.2599 361.731 104.57C380.491 125.88 393.99 151.293 401.144 178.768C408.298 206.243 408.906 235.013 402.92 262.766C396.935 290.518 384.522 316.48 366.68 338.564L365.455 337.574C383.143 315.682 395.448 289.946 401.381 262.434C407.315 234.922 406.712 206.401 399.621 179.165C392.529 151.928 379.147 126.735 360.55 105.61C341.952 84.485 318.659 68.0173 292.541 57.5306L293.127 56.0696Z" />
                            </mask>
                            <path d="M293.127 56.0696C319.474 66.6482 342.971 83.2599 361.731 104.57C380.491 125.88 393.99 151.293 401.144 178.768C408.298 206.243 408.906 235.013 402.92 262.766C396.935 290.518 384.522 316.48 366.68 338.564L365.455 337.574C383.143 315.682 395.448 289.946 401.381 262.434C407.315 234.922 406.712 206.401 399.621 179.165C392.529 151.928 379.147 126.735 360.55 105.61C341.952 84.485 318.659 68.0173 292.541 57.5306L293.127 56.0696Z" stroke="#ffffff" strokeWidth="2" mask="url(#path-19-inside-5_115_2)" />
                        </g>
                    </g>
                </svg>

                <svg style={{ '--i': 3 }}  id="svg">
                    <g id="outter-center">
                        <g id="Ellipse 33">
                            <mask id="path-13-inside-2_115_2" fill="white">
                                <path d="M189.514 348.899C211.061 355.133 233.858 355.662 255.672 350.436L255.068 347.917C233.691 353.039 211.35 352.52 190.233 346.411L189.514 348.899Z" />
                            </mask>
                            <path d="M189.514 348.899C211.061 355.133 233.858 355.662 255.672 350.436L255.068 347.917C233.691 353.039 211.35 352.52 190.233 346.411L189.514 348.899Z" stroke="#ffffff" strokeWidth="4" mask="url(#path-13-inside-2_115_2)" />
                        </g>
                        <g id="Ellipse 34">
                            <mask id="path-14-inside-3_115_2" fill="white">
                                <path d="M356.914 241.429C359.846 218.667 356.816 195.533 348.12 174.294L341.756 176.899C350.001 197.036 352.874 218.969 350.094 240.551L356.914 241.429Z" />
                            </mask>
                            <path d="M356.914 241.429C359.846 218.667 356.816 195.533 348.12 174.294L341.756 176.899C350.001 197.036 352.874 218.969 350.094 240.551L356.914 241.429Z" fill="#ffffff" stroke="#ffffff" strokeWidth="4" mask="url(#path-14-inside-3_115_2)" />
                        </g>
                        <path id="Subtract" d="M189.25 109.57L189.432 109.657L189.624 109.592C210.524 102.521 232.956 101.284 254.506 106.014C276.057 110.744 295.909 121.262 311.926 136.436L312.071 136.573H312.27H322.893C344.364 159.613 357.5 190.522 357.5 224.5C357.5 295.745 299.745 353.5 228.5 353.5C157.255 353.5 99.5 295.745 99.5 224.5C99.5 170.522 132.653 124.286 179.708 105.046L189.25 109.57Z" stroke="#ffffff" />
                    </g>
                </svg>

                <svg style={{ '--i': 4 }}  id="svg">
                    <g id="center-lines">
                        <circle id="Ellipse 36" cx="225.5" cy="224.5" r="93" stroke="#ffffff" />
                        <path id="Ellipse 45" d="M152.445 160.624C135.723 180.1 127.383 205.455 129.26 231.112C131.136 256.768 143.075 280.625 162.451 297.433C181.826 314.242 207.05 322.625 232.575 320.739C258.099 318.853 281.833 306.852 298.555 287.376C315.277 267.9 323.617 242.545 321.74 216.888C319.864 191.232 307.925 167.375 288.549 150.567C269.174 133.758 243.95 125.375 218.425 127.261C192.901 129.147 169.167 141.148 152.445 160.624L152.445 160.624Z" stroke="#ffffff" strokeWidth="8" strokeDasharray="1 20" />
                    </g>
                </svg>

                <svg style={{ '--i': 5 }}  id="svg">
                    <g id="center">
                        <path id="center_2" d="M173.051 160.818C183.357 152.33 195.57 146.47 208.64 143.741L209.406 147.646L209.598 148.626L210.579 148.435C213.252 147.913 215.974 147.53 218.739 147.291C202.333 148.709 187.389 155.238 175.508 165.278L176.271 164.633L175.626 163.869L173.051 160.818ZM268.968 159.114L271.131 155.768C282.255 163.153 291.42 173.128 297.84 184.836L294.328 186.706L293.445 187.177L293.916 188.06C298.136 195.966 301.026 204.69 302.286 213.934C297.387 178 267.858 149.902 231.266 147.211C244.593 148.191 256.981 152.541 267.584 159.411L268.424 159.955L268.968 159.114ZM300.731 247.009L304.553 248.1C300.733 260.894 293.865 272.57 284.539 282.125L281.715 279.301L281.008 278.594L280.301 279.301C292.512 267.089 300.668 250.821 302.572 232.692C302.101 237.178 301.247 241.55 300.044 245.774L299.77 246.735L300.731 247.009ZM225.346 303.006L225.389 307C212.037 306.982 198.888 303.723 187.073 297.504L188.969 294.006L189.445 293.128L188.567 292.651C186.556 291.559 184.599 290.38 182.7 289.12C194.964 297.259 209.678 302 225.5 302C225.779 302 226.057 301.998 226.335 301.996L225.335 302.006L225.346 303.006ZM149.854 245.575L146.04 246.69C142.449 233.83 142.033 220.29 144.827 207.234L148.705 208.113L149.682 208.335L149.902 207.358C152.935 193.924 159.459 181.81 168.471 172.022C155.761 185.826 148 204.257 148 224.5C148 242.991 154.476 259.969 165.283 273.291C158.889 265.41 154.012 256.249 151.095 246.254L150.814 245.294L149.854 245.575Z" fill="#ffffff" stroke="#ffffff" strokeWidth="2" />
                        <circle id="dot" cx="225.5" cy="224.5" r="14" stroke="#ffffff" />
                    </g>
                </svg>

            </div>
        </>
    )
}

export default HUDLoader